import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

(function () {
	window.onpageshow = function(event) {
		if (event.persisted) {
			window.location.reload();
		}
	};
})();

window.homepage = new Vue({
    el: ".homepage-form",
	components: {
		VueBootstrapTypeahead
	},
    data: {
        city: "Mumbai",
		deliveryType: "Delivery",
        pincode: "",
        coronaActive: true,
        submitting: false,
    },
    computed: {
		pins(){
			const pins = gon.pincodes.map(x => x.pin);
			return Array.from(new Set(pins));
		},
		mapAddress() {
			let returnMessage = "";
            if(this.deliveryType == "Delivery"){
                return "";
            } else {
                let pcObject = gon.pickup_locations.filter(x => x.name == this.deliveryType)[0];
                if(pcObject){
                    returnMessage = `${pcObject.map_address}`;
                }
            }
            return returnMessage;
		},
        shortAddress(){
            let returnMessage = "";
            if(this.deliveryType == "Delivery"){
                return "";
            } else {
                let pcObject = gon.pickup_locations.filter(x => x.name == this.deliveryType)[0];
                if(pcObject){
                    returnMessage = `${pcObject.preview_address}`;
                }
            }
            return returnMessage;
        },
		earliestDay(){
            let returnMessage = "";
            if(this.deliveryType == "Delivery"){
                let pc = ("" + this.pincode).replace(/\D/gm,"");
                let pcObject = gon.pincodes.filter(x => x.pin == pc)[0];
                if(pcObject){
                    returnMessage =  `Earliest delivery date - ${pcObject.earliest_date}`;
                }
            } else {
                let pcObject = gon.pickup_locations.filter(x => x.name == this.deliveryType)[0];
                if(pcObject){
                    returnMessage = `Earliest pickup date - ${pcObject.earliest_date}`;
                }
            }
            return returnMessage;
		},
		deliveryCategory(){
			let pc = ("" + this.pincode).replace(/\D/gm,"");
			let pcObject = gon.pincodes.filter(x => x.pin == pc)[0];
			if(pcObject){
				return pcObject.delivery_category;
			}
		},
		vashiCity() {
			return this.city == "Navi Mumbai";
		},
        thaneCity() {
            return this.city == "Thane";
        },
        mumbaiCity() {
            return this.city == "Mumbai";
        }
    },
    methods: {
		goTop(){
			window.scrollTo(0,0);
		},
        corona(){
            if(this.coronaActive && (this.thaneCity || this.vashiCity)){
                this.city = "Mumbai";
                swal("Temporarily Unavailable", "Due to precautions due to the coronavirus situation, we are temporarily not servicing Vashi or Navi Mumbai.", "");
            }
        },
        checkPincode(e){
			if(this.deliveryType == "Delivery"){
				if(this.pincode){
					let pc = ("" + this.pincode).replace(/\D/gm,"");
					if(gon.pincodes.map(x => x.pin).indexOf("" + pc) > -1) {
						this.submitting = true;
						return true;
					} else {
						swal("Error", "Sorry we do not deliver to that pincode presently. Please use the pickup option.");
					}
				} else {
					swal("Error", "Please enter a valid pincode");
				}
				e.preventDefault();
			}
        }
    }
});

window.homepage = homepage;
